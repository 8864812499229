import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div style={{ maxWidth: "1440px", margin: "auto" }}>
      <Suspense
        fallback={
          <div className="h-screen flex justify-content-center align-items-center">
            <i
              className="pi pi-spin pi-spinner-dotted"
              style={{ fontSize: "2rem" }}
            ></i>
          </div>
        }
      >
        <App />
      </Suspense>
    </div>
  </React.StrictMode>
);
